import "./styles.scss";

export function Propostas() {
  return (
    <section id="propostas" className="propostas">
      <div className="contexto">
        <h2>Propostas</h2>
        <div className="quadro-propostas">
          <div className="conteudo-proposta card">
            <h4>VALORIZAÇÃO DA SAÚDE</h4>
            <span>
              Apresentação de Projetos de Lei que visem garantir o acesso da
              população em tempo adequado aos atendimentos em postos de saúde,
              mediante o aprimoramento e ampliação das políticas de atenção
              básica e especializada da saúde da mulher, criança e idoso.
            </span>
          </div>
          <div className="conteudo-proposta card">
            <h4>SEGURANÇA PÚBLICA</h4>
            <span>
              Pretendo atuar firmemente na defesa da segurança com políticas
              públicas voltadas para promoção e valorização dos profissionais da
              segurança, pois somente investindo em segurança pública iremos
              proteger e assegurar a garantia dos direitos individuais do
              cidadão.
            </span>
          </div>
          <div className="conteudo-proposta card">
            <h4>EDUCAÇÃO</h4>
            <span>
              Valorização dos profissionais da rede municipal de ensino com
              investimentos na educação, ampliando a oferta de vagas integrais
              nas Emeis, para que os pais possam trabalhar e ter a certeza de
              que seus filhos estarão seguros e cuidados.
            </span>
          </div>
          <div className="conteudo-proposta card">
            <h4>PROMOÇÃO DO ESPORTE</h4>
            <span>
              Apresentação de ações legislativas que visem ampliar e revitalizar
              os campos de futebol, com investimentos na infraestrutura de
              vestiários, campo e equipamentos para promoção e inclusão social,
              propiciando o acesso das crianças e jovens ao esporte para que
              possam desfrutar de uma melhor qualidade de vida por meio do
              esporte.
            </span>
          </div>
          <div className="conteudo-proposta card">
            <h4>FISCALIZAÇÃO DO DINHEIRO PÚBLICO</h4>
            <span>
              Minha prioridade será a fiscalização da destinação das verbas
              públicas para que seja investido em projetos de qualidade e
              eficiência para a população da nossa cidade.
            </span>
          </div>
          <div className="conteudo-proposta card">
            <h4>DESTINAÇÃO DE 50% DO MEU SALÁRIO</h4>
            <span>
              Se eleito, comprometo a destinar 50% do meu salário, para projetos
              de leis e causas que possam beneficiar a população de BH.
            </span>
          </div>
          <div className="conteudo-proposta card">
            <h4>TOMBAMENTO DA FEIRA HIPPIE</h4>
            <span>
              Apresentação de Projeto de Lei para Tombamento da Feira Hippie
              como Patrimônio Imaterial da Cidade de Belo Horizonte, valorização
              e divulgação da Feira Hippie, instalações de lixeiras e redução
              dos valores das multas, aprimoramento da segurança pública com
              ampliação do efetivo da guarda municipal em toda a extensão da
              feira, instalação de base de segurança da Guarda Municipal,
              propiciando aos cidadãos a realização de suas compras e lazer com
              tranquilidade aos domingos.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
