import "./styles.scss";

export function ConhecaOGustavo() {
  return (
    <section id="conhecaOGustavo" className="conhecaOGustavo">
      <div className="contexto">
        <div className="titulo">
          <h3>Conheça o Gustavo</h3>
        </div>
        <div className="bio">
          <p>Sou Gustavo Damásio, nascido e criado em Belo Horizonte.</p>
          <p>
            {" "}
            Meu compromisso é defender ações legislativas nas áreas da saúde,
            educação, segurança pública e mobilidade urbana. Acredito no uso de
            novas tecnologias para melhorar a vida dos cidadãos.
          </p>
          <p>
            Vou fiscalizar a destinação dos recursos públicos e garantir uma
            gestão mais eficiente. Comprometo-me a destinar 50% do meu salário,
            se eleito, para projetos e causas que beneficiem a população de BH.
            Vamos juntos construir uma cidade melhor!
          </p>
        </div>
      </div>
    </section>
  );
}
