import "./styles.scss";
import instagramLogo from "../../assets/instagram.svg";
import facebookLogo from "../../assets/facebook.svg";

export function Footer() {
  return (
    <footer id="redes">
      <div className="contexto">
        <h4>Minhas Redes</h4>
        <div className="redes">
          <a href="http://instagram.com/gustavodamasiopereira">
            <img src={instagramLogo} alt="Instagram" /> <span>Instagram</span>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100085050461064">
            <img src={facebookLogo} alt="Facebook" /> <span>Facebook</span>
          </a>
        </div>
        <small>56.326.687/0001-04 CNPJ - PROPAGANDA ELEITORAL</small>
      </div>
    </footer>
  );
}
