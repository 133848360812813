import "./App.scss";
import { Header } from "./components/header";
import { Banner } from "./components/banner";
import { ConhecaOGustavo } from "./components/bio";
import { Propostas } from "./components/propostas";
import { Footer } from "./components/footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <ConhecaOGustavo />
      <Propostas />
      <Footer />
    </div>
  );
}

export default App;
