import { useState } from "react";
import "./styles.scss";
import logoPSDB from "../../assets/logoPSDB.png";
import barras from "../../assets/barras.svg";

export function Header() {
  const [estaMostrandoLinks, setEstaMostrandoLinks] = useState(false);
  const toggleExibirLink = () => setEstaMostrandoLinks(!estaMostrandoLinks);
  return (
    <header>
      <div className="contexto">
        <div className="contexto-inner">
          <img src={logoPSDB} alt="Logo partido PROS" className="logo" />
          <div className="links">
            <a href="#conhecaOGustavo">Conheça o Gustavo</a>
            <a href="#propostas">Propostas</a>
            <a href="#redes">Minhas Redes</a>
          </div>
          <button onClick={toggleExibirLink}>
            <img src={barras} alt="Menu navegação" />
          </button>
        </div>
        <div className="links-mobile">
          <div className={`wrapper ${estaMostrandoLinks ? "exibir" : ""}`}>
            <a href="#conhecaOGustavo" onClick={toggleExibirLink}>
              Conheça o Gustavo
            </a>
            <a href="#propostas" onClick={toggleExibirLink}>
              Propostas
            </a>
            <a href="#redes" onClick={toggleExibirLink}>
              Minhas Redes
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
