import "./styles.scss";
import foto from "../../assets/fotonova.png";

export function Banner() {
  return (
    <section className="banner">
      <div className="teste"></div>
      <div className="contexto">
        <div className="container">
          <div className="cta laranja">
            <h1>Para Vereador em BH</h1>
            <h1>Gustavo Damásio</h1>
            <h1>45222</h1>
            <h1>Juntos por uma BH Melhor</h1>
          </div>

          <img src={foto} alt="Foto de Gustavo" />
        </div>
      </div>
      <a href="http://www.freepik.com">Designed by coolvector / Freepik</a>
    </section>
  );
}
